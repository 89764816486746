import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-olvidarpass',
  templateUrl: './olvidarpass.component.html',
  styleUrls: ['./olvidarpass.component.css'],
})
export class OlvidarpassComponent implements OnInit {
  email: string;
  response: string;
  usuario: string;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  enviar() {
    this.usuario = this.usuario.toLowerCase();
    if (this.usuario === null || this.usuario === '') {
      this.translate.get('olvidar.debeusuario').subscribe((data) => {
        this.response = data;
        return;
      });
    }
    this.userService.getUserByUsuario(this.usuario).subscribe((user) => {
      if (user.size == 0) {
        this.translate.get('olvidar.datosequivocados').subscribe((data) => {
          this.response = data;
          return;
        });
      }
      var datos = user[0].data();
      // user.forEach((us:any) => {
      this.email = datos.email;
      this.authenticationService
        .resetemail(this.email)
        .then((ok: any) => {
          this.translate.get('olvidar.link').subscribe((data) => {
            this.response = data;

            setTimeout(() => {
              this.router.navigate(['/login']);
            }, 2000);
          });
        })
        .catch((error: any) => {
          if (error.code === 'auth/user-not-found') {
            this.translate.get('olvidar.emailnoexiste').subscribe((data) => {
              this.response = data;
            });
          } else {
            this.translate.get('olvidar.error').subscribe((data) => {
              this.response = data;
            });
          }
        });
      // });
    });
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  emailRestablecer() {
    this.response = '';
    this.usuario = this.usuario ? this.usuario.toLowerCase() : '';

    if (this.usuario === '') {
      this.translate.get('olvidar.debeusuario').subscribe((data) => {
        this.response = data;
      });
      return;
    }

    this.userService
      .getUserByUsuario(this.usuario)
      .pipe(first())
      .subscribe((user:any) => {
        if (user.size === 0) {
          this.translate.get('olvidar.datosequivocados').subscribe((data) => {
            this.response = data;
          });
        } else {
          let us = user.docs[0];
          this.email = us.data().email;
          const userdate = {
            userEmail: this.email,
            redirectUrl: 'https://thecampus.education/login',
          };
          const url =
            'https://us-central1-the-campus-prod.cloudfunctions.net/app/restablecer';

          fetch(url, {
            method: 'POST',
            body: JSON.stringify(userdate),
          })
            .then((response) => {
              if (response.status !== 500) {
                this.translate.get('olvidar.link').subscribe((data) => {
                  this.response = data;
                  setTimeout(() => {
                    this.router.navigate(['/login']);
                  }, 2000);
                });
              } else {
                this.translate.get('olvidar.error').subscribe((data) => {
                  this.response = data;
                });
              }
            })
            .catch((error) => {
              console.log('error', error);
            });
        }
      });
  }

  restablecerTest() {
    this.response = '';
    this.usuario = this.usuario ? this.usuario.toLowerCase() : '';

    if (this.usuario === '') {
      this.translate.get('olvidar.debeusuario').subscribe((data) => {
        this.response = data;
      });
      return;
    }

    this.userService
      .getUserByUsuario(this.usuario)
      .pipe(first())
      .subscribe((user:any) => {
        if (user.size === 0) {
          this.translate.get('olvidar.datosequivocados').subscribe((data) => {
            this.response = data;
          });
        } else {
          let us = user.docs[0];
          this.email = us.data().email;
          const userdate = {
            userEmail: this.email,
            redirectUrl: 'https://thecampus.education/login',
          };
          console.log('json:', userdate);
          const url =
            'https://us-central1-the-campus-prod.cloudfunctions.net/app/restablecerTest';

          fetch(url, {
            method: 'POST',
            body: JSON.stringify(userdate),
          })
            .then((response) => response.text())
            .then((text) => {
              console.log(text);
              // Puedes agregar más procesamiento aquí si es necesario
            })
            .catch((error) => {
              console.log('error', error);
            });
        }
      });
  }
}
