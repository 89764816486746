import { Component, Input, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-clasesbox-dashboard',
  templateUrl: './clasesbox-dashboard.component.html',
  styleUrls: ['./clasesbox-dashboard.component.css'],
})
export class ClasesboxDashboardComponent implements OnInit {
  @Input() clase: any;
  @Input() profesor: any;
  @Input() nombres: any;
  @Input() apellidos: any;
  @Input() uid: any;
  totalNotificaciones = 0;

  administradores = [];
  profesorPrincipal: any = {};

  constructor(
    private breadService: BreadcrumbService,
    private userService: UserService,
    private notificacionesServices: NotificacionesService
  ) {}

  ngOnInit(): void {
    this.getProfesores();
    this.getNotificaciones();
  }

  irSala() {
    let administradores = this.clase.payload?.doc.data().administradores;
    let sala = this.clase.payload?.doc.data().urlsala;
    console.log(sala);
    if (sala == null) {
      if (administradores.length > 0) {
        let nombreTitle = this.toTitleCase(this.nombres);
        let apellidosTitle = this.toTitleCase(this.apellidos);

        window.open(
          'https://in2.thecampus.education/webapp3/#/?conference=' +
            this.profesorPrincipal.usuario +
            '&name=' +
            nombreTitle +
            ' ' +
            apellidosTitle +
            '&role=guest&callType=video&join=1'
        );
      }
    } else {
      window.open(
        //
        sala
      );
    }
  }

  getProfesorPrincipal() {
    this.userService.getUserById(this.administradores[0]).subscribe((data) => {
      this.profesorPrincipal = data[0].payload.doc.data();
    });
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  UpdateBreadcrumb(name, url) {
    this.breadService.addtobread(name, url);
  }
  getProfesores() {
    this.administradores = this.clase.payload?.doc.data().administradores;
    this.getProfesorPrincipal();
  }

  getNotificaciones() {
    //Buscar por grupo y uid del remitente
    this.notificacionesServices
      .getNotificacionesMensajesGrupo(this.clase.payload?.doc.id, this.uid)
      .subscribe((mensajes) => {
        this.totalNotificaciones = 0;
        if (mensajes.length > 0) {
          this.totalNotificaciones = mensajes.length;
        }
      });
  }
}
