<div class="claseheader">
  <div class="box headinguser">
    <div class="notificacionnumber" *ngIf="numeromensajes>1">
      {{numeromensajes}}
    </div>
    <div
      class="avatarbox"
      routerLink="/dashboard/profile/{{ datosEmisor.uid }}"
      
    >
      <img
        *ngIf="datosEmisor.avatar; else default"
        [src]="datosEmisor.avatar"
      />
      <ng-template #default>
        <img src="../../../../assets/img/defaultapp.jpg" alt="" />
      </ng-template>
      <p>
        <b>
          {{ datosEmisor.nombres | titlecase }}
          {{ datosEmisor.apellidos | titlecase }}
        </b>
      </p>
    </div>

    <div class="boxtitles" *ngIf="this.datosNotificacion.mensaje">
      <h4>
        {{ datosEmisor.nombres | titlecase }}
        {{ datosEmisor.apellidos | titlecase }}:
      </h4>
      <h4>{{ notificacion.payload.doc.data().texto }}</h4>
    </div>
     <div class="boxtitles" *ngIf="this.datosNotificacion.grupo">
        <h4>
          {{ datosEmisor.nombres | titlecase }}
          {{ datosEmisor.apellidos | titlecase }}:
        </h4>
        <h4>{{ notificacion.payload.doc.data().texto }}</h4>
      </div>
    <div class="boxtitles" *ngIf="this.datosNotificacion.event_id">
      <h4 translate> dashboard.notificaciones.evento</h4>
      <h4>{{ notificacion.payload.doc.data().texto }}</h4>
    </div>
    <div
      class="rightbuttons"
      *ngIf="this.datosNotificacion.mensaje"
    >
      <ng-container *ngIf="notificacion.payload.doc.data().url">
        <a
          (click)="deletenotificacion()"
          routerLink="{{ notificacion.payload.doc.data().url }}"
          translate
          >
          dashboard.notificaciones.ver
        </a>
      </ng-container>

      <br />

      <!-- <a (click)="deletenotificacion()" translate>
        dashboard.notificaciones.eliminar </a> -->
    </div>

    <div class="rightbuttons" *ngIf="this.datosNotificacion.event_id">
      <ng-container *ngIf="notificacion.payload.doc.data().url">
        <a
          (click)="vernotificacion(notificacion.payload.doc.id)"
          routerLink="{{ notificacion.payload.doc.data().url }}"
          translate
          >
          dashboard.notificaciones.ver
        </a>
      </ng-container>

      <br />

      <a (click)="deletenotificacion()" translate>
        dashboard.notificaciones.eliminar </a>
    </div>
  </div>
</div>




