<!-- clase box -->
<div class="subbasicbox2">
  <div class="boximage">
    <a
      routerLink="/dashboard/clase/{{ clase.payload?.doc.id }}"
      (click)="
        UpdateBreadcrumb(
          clase.payload?.doc.data().nombre,
          '/dashboard/clase/' + clase.payload?.doc.id
        )
      "
    >
      <img
        *ngIf="clase.payload?.doc.data().imagen; else defaultclassavatar"
        src="{{ clase.payload?.doc.data().imagen }}"
        alt="{{ clase.payload?.doc.data().nombre | titlecase }}"
      />
      <ng-template #defaultclassavatar
        ><img
          src="assets/img/clase_default.jpg"
          alt="{{ clase.payload?.doc.data().nombre | titlecase }}"
      /></ng-template>
    </a>
  </div>
  <div class="boxtitles">
    <h4>
      <a
        routerLink="/dashboard/clase/{{ clase.payload?.doc.id }}"
        (click)="
          UpdateBreadcrumb(
            clase.payload?.doc.data().nombre,
            '/dashboard/clase/' + clase.payload?.doc.id
          )
        "
        >{{ clase.payload?.doc.data().nombre | titlecase }}
      </a>
    </h4>
    <p>
      {{ clase.payload?.doc.data().colegio_nombre | titlecase }}
    </p>
  </div>
  <div>
    <!--<div class="number">
                                       3
                                   </div>-->
    <div class="videocall">
      <div class="notificationnumber" *ngIf="totalNotificaciones > 0">
        {{ totalNotificaciones }}
      </div>
      <a
        routerLink="/dashboard/clase/{{ clase.payload?.doc.id }}"
        (click)="
          UpdateBreadcrumb(
            clase.payload?.doc.data().nombre,
            '/dashboard/clase/' + clase.payload?.doc.id
          )
        "
      >
        <img src="/assets/img/chatbox-b.svg" />
      </a>
    </div>
  </div>

  <div class="boxdata">
    <h4 translate>dashboard.profesortitular</h4>
    <p id="pname">{{ profesorPrincipal.nombres | titlecase }}</p>
  </div>

  <div class="boxvideocall">
    <div class="videocall">
      <a title="The campus video" (click)="irSala()">
        <img src="/assets/img/videocall.svg" alt="" />
      </a>
    </div>
  </div>
</div>
<!--/clase box -->
