import { ClasesService } from './../../services/clases.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Clases } from 'src/app/interfaces/clases';
import { UserService } from './../../services/user.service';
import { ChatService } from './../../services/chat.service';
import { AuthenticationService } from '../../services/authentication.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

@Component({
  selector: 'app-clase',
  templateUrl: './clase.component.html',
  styleUrls: ['./clase.component.css'],
})
export class ClaseComponent implements OnInit, OnDestroy {
  claseuid: string;
  clase: any;
  administradores = [];
  estudiantes = [];
  messagechatclase: any;
  currentuserid: string;
  chatusers = [];
  searchValue: string;
  chatorimessa = [];
  userchat: any;
  addestudiantes: any;
  addadmins: any;
  currentuseremail: any;

  userdata: any = { rol: '' };
  nombres: any;
  apellidos: any;

  clasesCapadas = [
    '0MYFZ2lrZKZ69JjARsVB',
    '0MYFrkjV4l2THtX6CLGZ',
    '000LlElEx00000000',
    '000LlElEx00000001',
    '000LlElEx00000002',
    '000LlElEx00000003',
    '000LlElEx00000004',
    '000LlElEx00000005',
    '000LlElEx00000006',
    '000LlElEx00000007',
    '000LlElEx00000008',
    '000LlElEx00000009',
    '000LlElEx00000010',
    '000LlElEx00000011',
    '000LlElEx00000012',
    '000LlElEx00000013',
    '000LlElEx00000014',
    '000LlElEx00000015',
    '000LlElEx00000016',
    '000LlElEx00000017',
    '000LlElEx00000018',
  ];

  ocultarClase: boolean = true;
  obsNotificacion: any = null;
  constructor(
    private activatedRoute: ActivatedRoute,
    private clasesService: ClasesService,
    private userService: UserService,
    private chatService: ChatService,
    private notificacionesService: NotificacionesService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.claseuid = params['uid'];
      if (this.clasesCapadas.includes(this.claseuid)) {
        this.ocultarClase = true;
      } else {
        this.ocultarClase = false;
      }
      this.administradores = [];

      const Userdata: any = this.userService.getUserinfodata();
      if (Userdata === undefined) {
        this.authenticationService.getStatus().subscribe(
          (status) => {
            this.currentuseremail = status.email;
            this.currentuserid = status.uid;
            this.authenticationService
              .isUserAdmin(this.currentuserid)
              .subscribe((userdata) => {
                if (userdata[0]) {
                  this.userdata = userdata[0];
                  this.nombres =
                    this.userdata.nombres; /* nombres del usuario logueado */
                  this.apellidos =
                    this.userdata.apellidos; /*apellidos del usuario logueado */
                  this.deleteNotifications();
                }
              });
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.currentuseremail = Userdata.payload.doc.data().email;
        this.currentuserid = Userdata.payload.doc.data().uid;
        this.userdata = Userdata.payload.doc.data();
        this.nombres = this.userdata.nombres; /* nombres del usuario logueado */
        this.apellidos =
          this.userdata.apellidos; /*apellidos del usuario logueado */
        this.deleteNotifications();
      }

      this.clasesService.getClaseByIdNow(this.claseuid).subscribe((laclase) => {
        if (laclase) {
          this.clase = laclase.data();
          if (this.clase.administradores.length > 0) {
            this.administradores = [];
            for (
              let index = 0;
              index < this.clase.administradores.length;
              index++
            ) {
              let a = this.userService
                .getUserByIdNow(this.clase.administradores[index])
                .subscribe((uadmin) => {
                  uadmin.forEach((element) => {
                    this.addadmins = element.data();

                    this.administradores[index] = element.data();
                  });
                });
            }
          }

          this.clase.usuarios.forEach((estudiantes) => {
            this.estudiantes = [];
            this.userService
              .getUserById(estudiantes)
              .subscribe((uestudiantes) => {
                this.addestudiantes = uestudiantes[0].payload.doc.data();
                if (
                  !this.estudiantes.some(
                    (el) => el.uid === this.addestudiantes.uid
                  )
                ) {
                  this.estudiantes.push(uestudiantes[0].payload.doc.data());
                }
              });
          });
          this.getmessages();
        }
      });
    });
  }

  deleteNotifications() {
    this.obsNotificacion = this.notificacionesService
      .getNotificacionesMensajesGrupo(this.claseuid, this.currentuserid)
      .subscribe((mensajes) => {
        // if (cortarObservable) {
        // cortarObservable = false;
        console.log('mensajes hilo: ', mensajes);
        mensajes.forEach((mensaje) => {
          let id = mensaje.payload.doc.id;
          this.notificacionesService.removeNotificacion(id).then();
        });
        // }
      });
  }

  ngOnDestroy(): void {
    if (this.obsNotificacion != null) {
      this.obsNotificacion.unsubscribe();
    }
  }

  sendmessageclase() {
    if (this.currentuserid) {
      const message = {
        sender: this.currentuserid,
        message: this.messagechatclase,
        fecha: new Date(),
      };
      const chatclassid = 'clase-' + this.claseuid;
      this.chatService
        .sendmessageclase(chatclassid, message)
        .then((datames) => {
          this.estudiantes.forEach((estudiante) => {
            if (estudiante.uid != this.currentuserid) {
              var notification = {
                user: estudiante.uid,
                texto: '🗨️ ' + this.clase.nombre + ': ' + +message.message,
                url: '/dashboard/clase/' + this.claseuid,
                verlo: estudiante.uid,
                fecha: new Date(),
                emisor: this.currentuserid,
                mensaje: false,
                grupo: this.claseuid,
              };
              this.notificacionesService.createdNotificacion(notification);
            }
          });
          this.messagechatclase = '';
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getmessages() {
    const chatclassid = 'clase-' + this.claseuid;
    this.chatService.getClaseMessages(chatclassid).subscribe((message) => {
      this.chatorimessa = message;
      this.chatorimessa.forEach((messageData: any) => {
        this.userService
          .getUserById(messageData.payload.doc.data().sender)
          .subscribe((userchat) => {
            this.userchat = userchat[0].payload.doc.data();
            if (!this.chatusers[this.userchat.uid]) {
              this.chatusers[this.userchat.uid] =
                userchat[0].payload.doc.data();
            }
          });
      });
    });
  }

  trackByArtNo(index: number, item: any): string {
    return item.uid;
  }

  scrolltobottom() {
    const objDiv = document.getElementById('themessagesbox');
    objDiv.scrollTop = objDiv.scrollHeight + 1000;
  }
}
