import { User } from './../interfaces/user';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import {HttpClient} from '@angular/common/http';
import {HttpParams} from '@angular/common/http';
import { Subject } from 'rxjs';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class UserService {
  pexipdata = [];
  userdata: any;
  datosDeUsuario = new Subject();

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
    private db: AngularFireDatabase
  ) {}

  getUsers() {
    return this.firestore.collection('users').snapshotChanges();
  }
  getUsersNow() {
    return this.firestore.collection('users').get();
  }
  getUserByUsuario(usuario) {
    return this.firestore
      .collection('users', (ref) => ref.where('usuario', '==', usuario))
      .get();
  }
  getUserById(uid) {
    return this.firestore
      .collection('users', (ref) => ref.where('uid', '==', uid))
      .snapshotChanges();
  }
  getUserByIdNow(uid) {
    return this.firestore
      .collection('users', (ref) => ref.where('uid', '==', uid))
      .get();
  }
  getUserByEmail(email) {
    return this.firestore
      .collection('users', (ref) => ref.where('email', '==', email))
      .get();
  }

  getUserCodigo(codigo) {
    return this.firestore
      .collection('users', (ref) => ref.where('codigo', '==', codigo))
      .get();
  }
  getUserColegio(colegio) {
    return this.firestore
      .collection('users', (ref) =>
        ref.where('colegios', 'array-contains', colegio)
      )
      .get();
  }

  getUserByColegioAndRole(colegio: string) {
    return this.firestore
      .collection('users', (ref) =>
        ref
          .where('colegios', 'array-contains', colegio)
          .where('rol', '==', 'estudiante')
      )
      .get();
  }

  createdUser(user: User) {
    return this.firestore.collection('users').add(user);
  }

  editUser(user: any, uiduserdatabase) {
    //console.log(theuser[0].payload.doc.id);
    return this.firestore.collection('users').doc(uiduserdatabase).update(user);
  }

  // searchUsers(clases) {
  //   return this.firestore.collection('users', ref => ref.where('clases', 'array-contains-any', clases)).snapshotChanges();
  // }
  searchUsers(clases) {
    return this.firestore
      .collection('users', (ref) =>
        ref.where('clases', 'array-contains-any', clases)
      )
      .snapshotChanges();
  }
  searchUsersClases(clases) {
    const batchSize = 10;
    let batches = [];
    for (let i = 0; i < clases.length; i += batchSize) {
      batches.push(clases.slice(i, i + batchSize));
    }

    let observables = batches.map((batch) =>
      this.firestore
        .collection('users', (ref) =>
          ref.where('clases', 'array-contains-any', batch)
        )
        .snapshotChanges()
    );

    return combineLatest(observables).pipe(
      map((results) => results.reduce((acc, cur) => acc.concat(cur), []))
    );
  }
  searchUsersCursos(cursos) {
    return this.firestore
      .collection('users', (ref) =>
        ref.where('cursos', 'array-contains-any', cursos)
      )
      .snapshotChanges();
  }

  searchUsers_old(name) {
    return this.firestore
      .collection('users', (ref) =>
        ref
          .orderBy('nombres')
          .startAt(name.toLowerCase())
          .endAt(name.toLowerCase() + '\uf8ff')
          .limit(10)
      )
      .snapshotChanges(['added', 'removed']);
  }

  getPresence(uid: string) {
    return this.db.object(`status/${uid}`).valueChanges();
  }

  getUserinfodata() {
    return this.userdata;
  }

  setUserinfo(data) {
    this.userdata = data;
    this.datosDeUsuario.next(data);
  }

  getUserinfo() {
    return this.datosDeUsuario;
  }

  getUsersbyClass(classuid) {
    return this.firestore
      .collection('users', (ref) =>
        ref.where('clases', 'array-contains', classuid)
      )
      .snapshotChanges();
  }
  getUsersbyColegio(colegioid) {
    return this.firestore
      .collection('users', (ref) =>
        ref.where('colegios', 'array-contains', colegioid)
      )
      .snapshotChanges();
  }

  deleteUser(uid) {
    return this.firestore.collection('users').doc(uid).delete();
  }
}
