<!-- <iframe [src]="iframe|safe: 'resourceUrl'" frameborder="0"></iframe> -->

<div class="contenedor">
  <div class="videocontendedor">
    <div #scriptContainer class="video">
      <!-- <div id="web-player-0"></div> -->
      <div [(id)]="'rec-live-' + idSala"></div>
    </div>
  </div>
  <!-- <div class="footer">
    <img src="/assets/img/footerLive.jpg" alt="" />
  </div> -->
</div>
